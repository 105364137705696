import React, { useState } from 'react';
import './CookiesPopup.css'

const CookiesPopup = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [showOptions, setShowOptions] = useState(false);
  const [preferences, setPreferences] = useState({
    essential: true,
    analytics: false,
    marketing: false,
  });

  const handleAcceptAll = () => {
    setPreferences({ essential: true, analytics: true, marketing: true });
    savePreferences({ essential: true, analytics: true, marketing: true });
    setIsVisible(false);
  };

  const handleSavePreferences = () => {
    savePreferences(preferences);
    setIsVisible(false);
  };

  const savePreferences = (prefs) => {
    localStorage.setItem('cookiePreferences', JSON.stringify(prefs));
  };

  const handleToggle = (type) => {
    setPreferences(prevPrefs => ({
      ...prevPrefs,
      [type]: !prevPrefs[type],
    }));
  };

  if (!isVisible) return null;

  return (
    <div className="cookies-popup">
      <div className="cookies-content">
        <h2>Cookie Preferences</h2>
        <p>We use cookies to enhance your experience. Manage your preferences below:</p>
        <button className="show-options-button" onClick={() => setShowOptions(!showOptions)}>
          {showOptions ? 'Hide Options' : 'Show Options'}
        </button>
        {showOptions && (
          <div className="cookies-options">
            <div>
              <label>
                <input 
                  type="checkbox" 
                  checked={preferences.essential} 
                  disabled 
                />
                Essential Cookies
              </label>
            </div>
            <div>
              <label>
                <input 
                  type="checkbox" 
                  checked={preferences.analytics} 
                  onChange={() => handleToggle('analytics')} 
                />
                Analytics Cookies
              </label>
            </div>
            <div>
              <label>
                <input 
                  type="checkbox" 
                  checked={preferences.marketing} 
                  onChange={() => handleToggle('marketing')} 
                />
                Marketing Cookies
              </label>
            </div>
          </div>
        )}
        <div className="cookies-buttons">
          <button onClick={handleAcceptAll}>Accept All</button>
          <button onClick={handleSavePreferences}>Save Preferences</button>
        </div>
      </div>
    </div>
  );
};

export default CookiesPopup;
