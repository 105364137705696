import React from 'react';
import './global.css';  
import './Footer.css';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedinIn  } from 'react-icons/fa';

const Footer = () => {
    return (

        <footer >
            <div className='footer'>
            <div>
                <h3>Contact us</h3>
                <Link className='logo' to="/">GRAPHIQ STUDIO.</Link>
                <p>Email:  <a href="#contact">contact us form</a></p>
                <div className="social-media">
                <a href="https://www.facebook.com/graphiqstudio.eu" target="_blank" rel="noopener noreferrer">
                    <FaFacebook />
                </a>
                <a href="https://www.instagram.com/peter_lipo_illustrations/" target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                </a>
                <a href="https://www.linkedin.com/in/peterlipovsky/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedinIn />
                </a>
                </div>
            </div>
            <div>
                <h3>Our expertise</h3>
                <p>Brand identities</p>
                <p>Brand strategy</p>
                <p>Graphic design</p>
                <p>Web design and development</p>
                <p>Social media</p>
                <p>Copywriting</p>
            </div>
            <div>
                <h3>The agency</h3>
                <p><Link to="/about">About us</Link></p>
                <p><Link to="/">What we do</Link></p>
                <p><a href="#contact">Contact us</a></p>
                <p>Graphiq Studio Privacy Policy</p>
            </div>
            </div>
            <div className="footer-copyright">
        <p>&copy; {new Date().getFullYear()} Graphiq Studio. All rights reserved.</p>
      </div>
        </footer>
    );
};

export default Footer;
