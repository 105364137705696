import React, { useEffect } from 'react';
import ScrollToTop from './ScrollToTop';
import './AboutUs.css';  // Import the CSS file for styling
import ContactForm from './ContactForm';
import './global.css';  
import brandImg from '../img/components/team.png';
import designImg from '../img/components/painting_computer.png';
import shipFrameImg from '../img/components/back-ground.jpg';
import shipImg from '../img/components/ship.png';

const AboutUs = () => {

    useEffect(() => {
        const ship = document.querySelector('.ship');
        
        ship.addEventListener('click', () => {
            if (ship.style.left === '40%') {
                ship.style.left = '50%';
            } else {
                ship.style.left = '40%';
            }
        });
    }, []);

    return (
        <div className="about-us">
            <section className="about-header">
                <div className='content'>
                    <h1>About us</h1>
                    <p>Formulate - a straight-talking, no-nonsense creative branding agency with a personal touch.</p>
                    <div className='ship-container'>
                        <img className='shipBackground' src={shipFrameImg} alt="ship frame" />
                        <img className='ship' src={shipImg} alt="ship" />
                    </div>
                </div>
            </section>
            <section className="who-we-are">
                <div className='content'>
                    <div className="content-text">
                        <h2>Who we are</h2>
                        <p>Formulate provides dynamic, intelligent, and cohesive branding solutions to help our clients develop robust brands. Our experience in the Middle East and globally enables us to design meaningful branding strategies that engage and resonate with audiences. We create solutions tailored to each client’s unique needs, focusing on creating impact and delivering results.</p>
                    </div>
                    <div className="image-container">
                        <img src={brandImg} alt="Branding Image" />
                    </div>
                </div>
            </section>
            <section className="what-we-do">
                <div className='content'>
                    <div className="content-text">
                        <h2>What we do</h2>
                        <p>We specialize in creating and executing branding strategies that align with our clients' business goals. Our services include brand identity design, brand strategy development, graphic design, web design and development, social media management, and copywriting. We ensure that each touchpoint of the brand reflects the core values and message of the company.</p>
                    </div>
                    <div className="image-container">
                        <img src={designImg} alt="Design Image" />
                    </div>
                </div>
            </section>
            <section className="our-team">
                <div className='content'>
                    <h2>Our team</h2>
                    <p>Our founding partners have over 30 years of combined design experience, with a range of clients in their portfolios, from major brands and agencies to small businesses and startups. They are supported by a selection of hand-picked experts who bring their unique skills to our projects.
                    </p>
                </div>
            </section>
            <ContactForm /> 
            <ScrollToTop />
        </div>
    );
};

export default AboutUs;
