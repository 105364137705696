import React, { useState } from 'react';
import './PricingPage.css';

const scrollToContact = () => {
    document.getElementById('contact-form').scrollIntoView({ behavior: 'smooth' });
};

const PricingPage = () => {
    const [selectedPlan, setSelectedPlan] = useState('');
    const [visiblePrice, setVisiblePrice] = useState({
        wordpress: false,
        static: false,
        ecommerce: false,
        enterprise: false,
    });
    const [showAdditionalCharges, setShowAdditionalCharges] = useState({
        wordpress: false,
        static: false,
        ecommerce: false,
        enterprise: false,
    });

    const handlePlanClick = (plan) => {
        setSelectedPlan(plan);
        setVisiblePrice((prev) => ({ ...prev, [plan]: true }));
    };

    const toggleAdditionalCharges = (plan) => {
        setShowAdditionalCharges((prev) => ({ ...prev, [plan]: !prev[plan] }));
    };

    return (
        <div className="pricing-page">
            <div className='content'>
            <h1>PRICING</h1>
            <div className="plans">
                <div
                    className={`plan ${selectedPlan === 'wordpress' ? 'selected' : ''}`}
                    onClick={() => handlePlanClick('wordpress')}
                >
                    <div className='top-details'>
                        <h2>WordPress / Squarespace Package</h2>
                        <div className="price">
                            Price: {visiblePrice.wordpress ? '€200' : 'click'}
                        </div>
                    </div>
                    <div className="details">
                        <button className="button-two" onClick={scrollToContact}>Contact</button>
                        <p>Features</p>
                        <ul>
                            <li>Up to 5 Sub-pages</li>
                            <li>Content Management System</li>
                            <li>Basic SEO Setup</li>
                            <li>Responsive Design</li>
                            <li>Contact Form</li>
                            <li>Hosting for one year</li>
                        </ul>
                        <p className="toggle-charges" onClick={() => toggleAdditionalCharges('wordpress')}>
                            Additional Charges {showAdditionalCharges.wordpress ? '▲' : '▼'}
                        </p>
                        {showAdditionalCharges.wordpress && (
                            <ul className="additional-charges">
                                <li>€20 per additional sub-page</li>
                                <li>€20 per year for hosting renewal</li>
                                <li>€30 per change after initial setup</li>
                                <li>€50 per Figma design</li>
                            </ul>
                        )}
                    </div>
                </div>
                <div
                    className={`plan ${selectedPlan === 'static' ? 'selected' : ''}`}
                    onClick={() => handlePlanClick('static')}
                >
                    <div className='top-details'>
                        <h2>Static Website Package</h2>
                        <div className="price">
                            Price: {visiblePrice.static ? '€400' : 'click'}
                        </div>
                    </div>
                    <div className="details">
                        <button className="button-two" onClick={scrollToContact}>Contact</button>
                        <p>Features</p>
                        <ul>
                            <li>Up to 5 Sub-pages</li>
                            <li>Basic SEO Setup</li>
                            <li>Responsive Design</li>
                            <li>Contact Form</li>
                            <li>Hosting for one year</li>
                        </ul>
                        <p className="toggle-charges" onClick={() => toggleAdditionalCharges('static')}>
                            Additional Charges {showAdditionalCharges.static ? '▲' : '▼'}
                        </p>
                        {showAdditionalCharges.static && (
                            <ul className="additional-charges">
                                <li>€20 per additional subpage</li>
                                <li>€20 per year for hosting renewal</li>
                                <li>€30 per change after initial setup</li>
                                <li>€50 per Figma design</li>
                            </ul>
                        )}
                    </div>
                </div>
                <div
                    className={`plan ${selectedPlan === 'ecommerce' ? 'selected' : ''}`}
                    onClick={() => handlePlanClick('ecommerce')}
                >
                    <div className='top-details'>
                        <h2>E-commerce Page Package</h2>
                        <div className="price">
                            Price: {visiblePrice.ecommerce ? '€500' : 'click'}
                        </div>
                    </div>
                    <div className="details">
                        <button className="button-two" onClick={scrollToContact}>Contact</button>
                        <p>Features</p>
                        <ul>
                            <li>Up to 5 Subpages</li>
                            <li>Static E-commerce Page</li>
                            <li>Basic SEO Setup</li>
                            <li>Simple CRM for managing products</li>
                            <li>Up to 3 different types of products</li>
                            <li>Contact Form</li>
                            <li>Hosting for one year</li>
                        </ul>
                        <p className="toggle-charges" onClick={() => toggleAdditionalCharges('ecommerce')}>
                            Additional Charges {showAdditionalCharges.ecommerce ? '▲' : '▼'}
                        </p>
                        {showAdditionalCharges.ecommerce && (
                            <ul className="additional-charges">
                                <li>€20 per additional subpage</li>
                                <li>€20 per year for hosting renewal</li>
                                <li>€30 per change after initial setup</li>
                                <li>€50 per Figma design</li>
                            </ul>
                        )}
                    </div>
                </div>
                <div
                    className={`plan ${selectedPlan === 'enterprise' ? 'selected' : ''}`}
                    onClick={() => handlePlanClick('enterprise')}
                >
                    <div className='top-details'>
                        <h2>Enterprise Package</h2>
                        <div className="price">
                            Price: {visiblePrice.enterprise ? 'Contact for pricing' : 'click'}
                        </div>
                    </div>
                    <div className="details">
                        <button className="button-two" onClick={scrollToContact}>Contact</button>
                        <p>Features</p>
                        <ul>
                            <li>Custom Business Solutions</li>
                            <li>Hosting & CMS Included</li>
                            <li>Custom Features (number of sites, designer accounts, etc.)</li>
                            <li>Add Code</li>
                            <li>Site Code</li>
                        </ul>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
};

export default PricingPage;
