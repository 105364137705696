import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import './Header.css';

const Header = () => {
    const [isResponsive, setIsResponsive] = useState(false);

    const toggleResponsive = () => {
        setIsResponsive(!isResponsive);
    };

    return (
        <header className="topnav" id="myTopnav">
            <div className="social-media container">
                <a href="https://www.facebook.com/graphiqstudio.eu" target="_blank" rel="noopener noreferrer">
                    <FaFacebook />
                </a>
                <a href="https://www.instagram.com/peter_lipo_illustrations/" target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                </a>
                <a href="https://www.linkedin.com/in/peterlipovsky/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedinIn />
                </a>
            </div>
            <div className="container">
                <Link className="logo active" to="/">GRAPHIQ STUDIO.</Link>
            </div>
            <div className="container">
                <nav className={isResponsive ? "nav-links responsive" : "nav-links"}>
                    <Link to="/about">About us</Link>
                    <Link to="/branding">Branding</Link>
                    <Link to="/graphic-design">Graphic Design</Link>
                    <Link to="/web-design">Web Design</Link>
                    <a href="javascript:void(0);" className="icon" onClick={toggleResponsive}>
                        <GiHamburgerMenu />
                    </a>
                </nav>
            </div>
        </header>
    );
};

export default Header;